import React from 'react'
import { Link } from 'react-router-dom'

class Footer extends React.Component {

	render() {
		return (
			<footer className=''>
				<div className='container'>
					<div className='row'>
						<div className='col-lg-4 col-sm-6 col-6'>
							<div className=''>
								<Link to='/'>
									<img src='/images/onx-logo-sm.png' alt='logo'/>
								</Link>

								<p className='txt-black mt-2 mb-0 copy-right'>© {(new Date().getFullYear())} Copyrights.</p> 
								<a href='/Onx-Company-Profile.pdf'> Download our company profile</a>
							</div>
						</div>
						<div className='col-lg-8 col-sm-6 col-6'>
							<nav className='navbar navbar-expand-lg navbar-light pull-right footer-menu'>
								<div className=' pull-right'>
							        <ul className='navbar-nav'>
							          <li className='nav-item'>
							            <Link className='nav-link' to='/get-started'>Contact us</Link>
							          </li>
							           
							        </ul>
							    </div>
					     	</nav>
					     	<div className='clearflex'>
						     	<div className='social-links pull-right mt-2'>
									<span><Link to='https://www.facebook.com/onxdigital/'>
										<i className='fa fa-facebook'></i></Link>
									</span>
									<span><Link to='https://www.linkedin.com/company/onxdigital/'><i className='fa fa-linkedin-square'></i></Link></span>
								</div>
							</div>	
						</div>
					</div>
				</div>
			</footer>
		)
	}
}

export default Footer