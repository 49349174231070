import React from 'react'
import Navigation from './Navigation'

class Header extends React.Component {

	render() {
		const {title} = this.props
		return (
			<React.Fragment>
				<Navigation/>
				<section className='banner-main page'>
					<img src='/images/hoem-banner-2-1.png' className='banner-main-img' alt='banner' />
					<div className='container'>
						<div className='banner-content text-center'>
							<h1 className='main-heading txt-white text-center p-4'>{title}</h1>
						</div>
					</div>
				</section>
			</React.Fragment>
		)
	}
}

export default Header