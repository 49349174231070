import React from 'react'
import Header from './Header'
import Footer from './Footer'

class AboutUs extends React.Component {

	render() {
		return (
			<React.Fragment>
				<Header title='About Us'/>
				<div className='main'>
					<div id="content" className="site-content">
						<section className="mt-0">
							<div className="container">
								<div className="row">
									<div className="col-lg-12 col-md-12 pr-lg-5 mb-5">
										<p className="txt-pink">Onx is a group of innovators, thinkers, development virtuosos and digital marketing fanatics with a passion for eCommerce that runs deep. We are an agency that has unparalleled experts devoted to every piece of the eCommerce puzzle. As a full-service eCommerce agency, we take a total-solutions approach to solving problems. We offer complete and integrated in-house services from start to finish.</p>
										<p className="txt-pink"> Thanks to our wide range of experiences, we have the unique ability to see the wider picture when it comes to Web technology. If we find that an online tool works for one industry, we can identify its potential to bring success to another industry. Our team spends every day perfecting the art of eCommerce while improving our clients’ eCommerce websites and their digital footprint. We started in 2012 and we’ve been rated amongst the top eCommerce digital marketing agencies. We specialize in increasing transactions and conversion rates while helping improve the user experience for our client’s customers along the way. </p>

										<p className="txt-pink">We’re a digital marketing agency that helps brands ignite their true eCommerce potential. Our developers are relentless problem solvers, looking for new ways to make eCommerce platforms do more. Our designers are meticulous architects, building out site designs by placing one element at a time. Our marketers are obsessive researchers, constantly gathering information about new methods and strategies.</p>

										<p className="txt-pink">We work best as the go-to digital team for committed eCommerce merchants who are enthusiastic about taking steps towards improving their online business. If that sounds like the kind of business owner you are, we want to work with you. Get to know us. We think you’ll want to work with us too.</p>

										<p className="txt-pink">We’ve helped eCommerce merchants from all over the globe reach new heights of success online. Our clients have reaped the rewards by utilizing our years of expertise, our top quality tools, and our passion to perfect the art of eCommerce.</p>

										<p className="txt-pink">In addition to experience, another key to our success is our commitment and dedication to providing the best possible customer service to our clients. While other companies claim to provide top-notch customer service, Onx actually delivers customer service that is unparalleled in the industry.</p>


										<p className="txt-pink">Whether Small or Large, We've Got You Covered!</p>

										<a href="/get-started" className="btn btn-custom txt-white mt-4">Get Started</a>
									</div>
								</div>
							</div>
						</section>

						<section className="mt-0">
							<div className="container">
								<div className="row">
									<div className="col-lg-6 col-md-6">
										<div className="text-center pt-5 mt-5">
											<img src="/images/info-ref-1252.png" alt="about"/>
										</div>
									</div> 
									<div className="col-lg-6 col-md-6 pt-5 pr-lg-5 mb-5">
										<h2 className="main-heading txt-black mmt-0 mt-md-5">Expertise that matters</h2>

										<p className="txt-pink">The websites that we have designed for our clients over the years have produced strong revenues and sales leads. We credit this success to several things: a design that is easy to navigate, a pleasant look and feel, and programming that abides by the indexing technology of search engines. Indeed, we are incredibly proud of the online stability and success our clients have achieved by employing our expertise.</p>

										<p className="txt-pink">Once your website is live, we hope that you continue to host your website with us in our world-className, secure data facility that is staffed 24/7/365. Another key to our success is our commitment and dedication to providing the best possible customer service to our clients.</p>

										<a href="/get-started" className="btn btn-custom txt-white mt-4">Get Started</a>
									</div>
								</div>
							</div>
						</section>

						<section className="header-WWD-secBg py-5">
							<div className="container">
								<div className="row">
									<div className="col-lg-6 col-md-6 pt-5 pr-lg-5 mb-5">
										<h2 className="main-heading txt-black mmt-0 mt-md-5">Why Choose Us</h2>
										<p className="txt-pink">Keeping your best interest in mind, we partner with the leading eCommerce platforms to give you options. Some eCommerce agencies charge premium prices only to steer you toward their one-size-fits-all solution. Who needs that? We offer a superior value proposition: When you work with us, we delve into your world and leverage our in-house expertise to discover which eCommerce platform is a perfect fit for you. </p>
										<p className="txt-pink">Our team focuses on communication, transparency, impeccable style and measurable results to extract the maximum potential out of each project. Using validated methods, our team of eCommerce design mavens builds your tailor-made plan of action. We cover all the bases, from your site strategy, creative eCommerce design, custom development, digital marketing, to ongoing maintenance.</p>
									</div>
									<div className="col-lg-6 col-md-6">
										<div className="text-center pt-5 mt-5">
											<img src="/images/info-img.png" alt="about"/>
										</div>
									</div> 
									<div className="col-lg-12 col-md-12">
										<p className="txt-pink">Together, we aim to become your partner in success. Now that you know what we do, you may we wondering who we are. We are a close-knit, diverse group of American, Canadian, and French professionals. You won’t get déjà vu when you meet us. Let’s rendezvous!</p>
										<p className="txt-pink">We make more than awesome websites. We make your entrepreneurial dreams a reality.</p>
										<a href="/get-started" className="btn btn-custom txt-white mt-4">Get Started</a>
									</div>
								</div>  
							</div>
						</section>

						<section className="py-5">
							<div className="container"> 
								<div className="row">
									<div className="col-md-4">
										<h5 className="txt-black my-4">OUR CORE VALUES &amp; CULTURE</h5>
										<p className="txt-pink">When you meet us you will quickly realize that every member of the Onx team is unique but there are 5 very important things we all have in common — our core values.</p>
									</div>
									<div className="col-md-4">
									<h5 className="txt-black my-4">THINK CREATIVELYE</h5>
										<p className="txt-pink">We know the classNameics and what’s trendy. Next, we blend abstract thinking power with years of experience to recommend one-of-a-kind, inventive solutions.</p>
									</div>
									<div className="col-md-4">
										<h5 className="txt-black my-4">BUILD RELATIONSHIPS</h5>
										<p className="txt-pink">Your business is more than a contract to us. We are your partners in success by creating value through solid communication, clear expectations, and a human touch.</p>
									</div>
									<div className="col-md-4">
										<h5 className="txt-black my-4">SHOW RESPECT</h5>
										<p className="txt-pink">Respect is given, trust is earned. Showing respect is the first step to a healthy, mutually beneficial business-agency relationship. We aim to earn your trust and loyalty.</p>
									</div>
									<div className="col-md-4">
										<h5 className="txt-black my-4">BE TRANSPARENT</h5>
										<p className="txt-pink">No sugar coating or dancing around the matter. We give crystal clear eCommerce expertise to keep you in-the-know with updates, reports, and measurable KPIs.</p>
									</div>
									<div className="col-md-4">
										<h5 className="txt-black my-4">FOCUS ON THE LONG TERM</h5>
										<p className="txt-pink">Not all solutions are equal. While novices cut corners, our team of experts are trained to think of long term wins that make your investment last well into the future.</p>
									</div>
								</div>
							</div>
						</section>
					</div>
				</div>	
				<Footer/>
			</React.Fragment>
		)
	}
}

export default AboutUs