import React from 'react'
import Navigation from './Navigation'


class Header extends React.Component {

	render() {
		return (
			<React.Fragment>
				<Navigation/>				
				<section className='banner-main'>
				    <div className='overlay'/>
				    <video width='100%' className='banner-main-video' muted autoPlay loop>
				            <source src='/images/Busy-4723.mp4' type='video/mp4'/>
				    </video>
				    <div className='container'>
				        <div className='banner-content text-center'>
				            <div className='homeTopHeading text-center' >
				                <h1 className='main-heading txt-white text-center p-4'>
				                    We are e-commerce design agency
				                </h1>
				                <p className='txt-white text-center'>We aim to make buying on the internet fun by creating user-centric experiences that <br/> convert into loyal customers.</p>
				                    <a href='/get-started' className='btn btn-custom txt-white mt-4'>Get Started</a>
				            </div>
				        </div>  
				    </div>
				</section>
			</React.Fragment>
		)
	}
}

export default Header