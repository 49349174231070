import React from 'react'
import Header from './Header'
import Footer from './Footer'

class Marketing extends React.Component {

	render() {
		return (
			<React.Fragment>
				<Header title='Marketing'/>
				<div className='main'>
					<div className="container">	
						<p><strong>Marketing Automation</strong></p>
						<p>With marketing automation, businesses can target customers with automated messages across email, web, social, and text. Messages are sent automatically, according to sets of instructions called workflows. Workflows may be defined by templates, custom-built from scratch, or modified mid-campaign to achieve better results. Marketing and sales departments use marketing automation to automate online marketing campaigns and sales activities to both increase revenue and maximize efficiency. When automation is used effectively to handle repetitive tasks, employees are free to tackle higher-order problems, and human error is reduced.</p>
						<p>Our digital marketing experts help you select the right platforms for your business and make the most of these automation tools. We’ll help you craft and plan strategic and personalized messages for your target audience, and improve sales activities with lead scoring, automated drip campaigns, and CRM integration. Our strategies are based on results, leveraging KPIs from these systems to determine outcomes from campaigns and tests and optimizing further activities.</p>
						<p><strong>Search Engine Optimization &amp; PPC</strong></p>
						<p>Successful SEO campaigns will always be the result of consistent execution and continuous analysis. An intelligently-crafted SEO strategy requires patience and a long-term commitment; you must accept the reality that it takes weeks or even months before you can expect serious results. When the work does eventually pay off, a more comprehensive SEO campaign will do so in a big way.</p>
						<p>Through a fully transparent process, we apply a multitude of refined SEO techniques and best practices to help brands drive revenue. Our team of SEO professionals audits your site and your overall digital brand presence to determine technical SEO issues, profitable keywords, and competitive analysis.</p>
						<p><strong>Social Media Marketing</strong></p>
						<p>Social media marketing, or SMM, is a form of internet marketing that involves creating and sharing content on social media networks in order to achieve your marketing and branding goals. Social media marketing includes activities like posting text and image updates, videos, and and other content that drives audience engagement, as well as paid social media advertising.</p>
						<p>Our social media management services save businesses more time and generate more results. Our company excels in all social Marketing services. Our work shows that our company knows how to optimize your social media channels to satisfy your marketing objectives. We have experience with converting social media advertising investments into more leads and sales. Whether your business is in real estate, consumer products, or nonprofits, our social media management services position your business for success.</p>
						<p><strong>Email Marketing</strong></p>
						<p>Marketing your products and services through email is a time-saving and cost-effective way to fill your sales pipeline with high-quality leads by connecting with new prospects and reengaging your existing customers. An innovative email marketing strategy allows you to target multiple client segments with personalized messaging for optimal prospect engagement.</p>
						<p>We’re experts in outbound marketing—and we know that effective email campaigns are the result of an airtight marketing strategy. Our team expertly assesses your business’s needs and goals to formulate a tailored strategy based on our findings. From there, our team goes into creation mode, creating or enriching contact lists of vetted data, crafting compelling copy, launching campaigns, and managing responses. We’re your full-service email marketing partner.</p>
					</div>
				</div>
				<Footer/>
			</React.Fragment>
		)
	}
}

export default Marketing