import React from 'react'
import ReactDOM from 'react-dom'
import { Router, Route, Switch } from 'react-router-dom'
import Home from './components/Home'
import AboutUs from './components/AboutUs'
import WhatWeDo from './components/WhatWeDo'
import Development from './components/Development'
import Optimization from './components/Optimization'
import Integration from './components/Integration'
import Marketing from './components/Marketing'
import GetStarted from './components/GetStarted'
import NotFound from './components/NotFound'
import DazPrivacyPolicy from './components/Daz/PrivacyPolicy'
import history from './history'


ReactDOM.render(
	<Router history={history}>
		<Switch>
			<Route exact path='/' component={Home}/>
			<Route exact path='/products' component={Home}/>
			<Route exact path='/daz' component={Home}/>
			<Route exact path='/daz-privacy-policy' component={DazPrivacyPolicy}/>
			<Route exact path='/privacy-policy' component={DazPrivacyPolicy}/>
			<Route exact path='/about-us' component={AboutUs}/>
			<Route exact path='/what-we-do' component={WhatWeDo}/>
			<Route exact path='/development' component={Development}/>
			<Route exact path='/optimization' component={Optimization}/>
			<Route exact path='/integrations' component={Integration}/>
			<Route exact path='/marketing' component={Marketing}/>
			<Route exact path='/get-started' component={GetStarted}/>
			<Route component={NotFound} />
		</Switch>
	</Router>, document.querySelector('#root'))
