import React from 'react'
import Header from './Header'
import Footer from './Footer'

class Development extends React.Component {

	render() {
		return (
			<React.Fragment>
				<Header title='Development'/>
				<div className='main'>
					<div className="container">
						<h2>WooCommerce:</h2>
						<p>WooCommerce is a product of <a href="https://automattic.com/" target="_blank" rel="noopener noreferrer">Automattic </a> and it is the most widely used E-Commerce platform in the world. As of 2019 WooCommerce has more than 5 million active installs and it powers 42% of all the ecommerces over the internet which gives it the largest market share within the industry. It has the a great community of people actively working to improve the platform itself and integrates with almost anything smoothly.</p>
						<p>Onx specializes in WordPress &amp; WooCommerce powered website solutions that often result in the use of advanced implementation or customization of themes and plugins. We have used WooCommerce and its extensions extensively on projects that have led to many challenging and exciting successful outcomes. From site data migrations, multi-channel integrations, performance and speed improvements, visual product configurators, and large e-commerce website that do millions of dollars of revenue – we have a deep understanding and appreciation for WordPress and WooCommerce and are confident in our ability to work with those technologies.</p>
						<h2>Magento:</h2>
						<p>Magento is an ecommerce platform built on open source technology which provides online merchants with a flexible shopping cart system, as well as control over the look, content and functionality of their online store. Magento offers powerful marketing, search engine optimization, and catalog-management tools.</p>
						<p>Here at Onx we have a proven track record in planning, designing, developing and successfully launching technically challenging e-commerce websites using Magento 1 and the recently released Magento 2 application. We understand that technical challenges face all new development projects and have finely tuned over many years an efficient and transparent process that ensures deadlines are met and completed within budget!</p>
						<h2>Shopify:</h2>
						<p>Magento is an ecommerce platform built on open source technology which provides online merchants with a flexible shopping cart system, as well as control over the look, content and functionality of their online store. Magento offers powerful marketing, search engine optimization, and catalog-management tools.</p>
						<p>Onx provides a broad range of digital, technology, and operations services to Shopify merchants of all sizes. Its years of industry experience, regional footprint, and digital capabilities, combined with Shopify’s powerful enterprise ecommerce platform, maximises opportunities to help businesses grow bigger, faster.</p>
						<h2>Mobile Commerce:</h2>
						<p>Everyone knows that e-commerce is a constantly growing part of the larger retail and commerce landscape, but did you know that mobile commerce is growing at an even faster rate? The first, and most important reason you should care about mobile commerce is simply the fact that more and more people now own smartphones and tablets and connect to the Internet using these devices. According to Mary Meeker’s 2014 Internet Trends report, smartphone usage has consistently grown over the past few years, so much so that 30% of mobile users are smartphone users.</p>
						<p>At Onx, we help you turn your idea into apps, solve problems and make your ecommerce app a potential goldmine. Onx boasts of standing in the top league when it comes to app development for iOS, Android and Windows. We have evolved into a one stop destination for mobile application creation to the final step of app marketing. To be a worthy offshore development company, we hire the most intelligent, proficient and tech-savvy mobile apps developers. Our developers have in-depth knowledge of major platforms/frameworks used for mobile development.
						</p>
						<a href="/get-started" className="btn btn-custom txt-white mt-4">Get Started</a>
					</div>
				</div>
				<Footer/>
			</React.Fragment>
		)
	}
}

export default Development