import React from 'react'
import HeaderHome from './HeaderHome'
import Footer from './Footer'

class Home extends React.Component {

	render() {
		return (
			<React.Fragment>
				<HeaderHome/>
				<div className='main'>
					<section className='mt-0  mt-md-5 bg-diagonal1 py-5'>
					    <div className='container'>
					        <div className='row'>
					            
					            <div className='col-lg-6 col-md-6 pt-5 pr-lg-5 mb-5'>
					                <h2 className='main-heading txt-black mmt-0 mt-md-5'>About us</h2>
					                <p className='txt-pink'>Onx is a group of innovators, thinkers, development virtuosos and digital marketing fanatics with a passion for eCommerce that runs deep. We are an agency that has unparalleled experts devoted to every piece of the eCommerce puzzle.</p>
					                <p className='txt-pink'>As a full-service eCommerce agency, we take a total-solutions approach to solving problems. We offer complete and integrated in-house services from start to finish.</p>
					                 
					            </div>
					            <div className='col-lg-6 col-md-6 contact-form'>
					            	<img src='/images/info-img.png' alt='about'/>
					            </div> 
					        </div>  
					    </div>
					</section>

					<section className='py-5'>
					    <div className='container'>
					        <h2 className='main-heading txt-black mmt-0 mt-md-5 text-center'>Our Process</h2>
					        <p className='txt-pink text-center'>Everything we do has a process we very strongly follow.</p>
					        <div className='row mt-5'>
					            <div className='col-md-4'>
					                <div className='process-box text-center'>
					                    <div className='process-icon'>
					                        <img src='/images/idea.png' width='55' className='' alt='idea'/>
					                    </div>
					                    <h3 className='txt-black my-4'>Ideation</h3>
					                    <p className='txt-pink'>Every project requires a thorough understanding of the requirements. In Ideation, we sit with you and only listen. We understand how your bussiness works, what are your requirements and how could we serve you best.</p>
					                </div>
					            </div>
					            <div className='col-md-4'>
					                <div className='process-box text-center'>
					                    <div className='process-icon'>
					                        <img src='/images/design.png' width='55' className='' alt='design'/>
					                    </div>
					                    <h3 className='txt-black my-4'>Design</h3>
					                    <p className='txt-pink'>In Design, all the effort spent to understand the process and requirment comes to life. It is where our thinkers, innovators and designers come together and sit to craft the perfect looking end product for you. </p>
					                </div>
					            </div>
					            <div className='col-md-4'>
					                <div className='process-box text-center'>
					                    <div className='process-icon'>
					                        <img src='/images/Development.png' width='55' className='' alt='development'/>
					                    </div>
					                    <h3 className='txt-black my-4'>Development</h3>
					                    <p className='txt-pink'>In all honesty, we could have the best looking eCommerce out there but if it is full of bugs, slow and inefficient it is of no use to your customers. This is where our expert developers come in.</p>
					                </div>
					            </div>
					        </div>

					        <div className='row mt-5'>
					                <div className='col-md-6'>
					                    <div className='process-box text-center'>
					                        <div className='process-icon'>
					                            <img src='/images/implement.png' width='55' className='' alt='implement'/>
					                        </div>
					                        <h3 className='txt-black my-4'>Implementation</h3>
					                        <p className='txt-pink'>What domain to buy to have the best results in search engines? What is the best hosting solution to host your website on? Autoscaling? Security? CDN? SSL? Caching?</p>
					                    </div>
					                </div>
					                <div className='col-md-6'>
					                    <div className='process-box text-center'>
					                        <div className='process-icon'>
					                            <img src='/images/audit.png' width='55' className='' alt='audit'/>
					                        </div>
					                        <h3 className='txt-black my-4'>Digital Audit</h3>
					                        <p className='txt-pink'>The real struggle of an eCommerce starts once its live! Who to advertise, what to advertise and how to advertise. To analyize through progress and status metrics and have a caculated way forward is necessary for an eCommerce.</p>
					                    </div>
					                </div>
					        </div>
					    </div>
					</section>  

					<section className='py-5 partners-sec mt-5'>
					    <div className='container'>
					        <div className='row'>
					            <div className='col-lg-8 my-5'>
					                <h2 className='txt-black my-4 main-heading text-black'> Our Clients </h2>
					                <p className='txt-pink'>Through continous dedication we have been abe built strong relationships with the companies we work with. The following are the gems we worked with.</p>
					            </div>
					        </div>
					        <div className='row'>


					            <div className='col-md-4'>
					                <div className='partner-info'>
					                    <div>
					                        <img src='/images/hub.jpg' alt='onx-hub' title='HUB Leather'/>
					                    </div>
					                </div>
					            </div>
					            <div className='col-md-4'>
					                <div className='partner-info'>
					                    <div>
					                        <img src='/images/fhs.png' alt='onx-fhs' title='FHS'/>
					                    </div>
					                </div>
					            </div>

					            <div className='col-md-4'>
					                <div className='partner-info'>
					                    <div>
					                        <img src='/images/HOBO.png' alt='onx-HOBO' title='HOBO'/>
					                    </div>
					                    
					                </div>
					            </div>
					            <div className='clearfix'/>
					        </div>
					        <div className='clearfix'/>

					        <div className='row mt-5'>
					            <div className='col-md-4'>
					                <div className='partner-info'>
					                    <div>
					                        <img src='/images/tcf.png' alt='onx-tcf' title='karmuqabla'/>
					                    </div>
					                    
					                </div>
					            </div>                

					            <div className='col-md-4'>
					                <div className='partner-info'>
					                    <div>
					                        <img src='/images/karmuqabla.png' alt='onx-karmuqabla' title='karmuqabla'/>
					                    </div>
					                    
					                </div>
					            </div>
					            <div className='col-md-4'>
					                <div className='partner-info'>
					                    <div>
					                        <img src='/images/barbqtonight.jpg' alt='onx-barbqtonight' title='BarBQ Tonight'/>
					                    </div>
					                </div>
					            </div>
					            <div className='clearfix'/>
					        </div>
					        <div className='row mt-5'>
					            <div className='col-md-4'>
					                <div className='partner-info'>
					                    <div>
					                        <img src='/images/gulkhan.jpeg' alt='onx-gulkhan' title='Gulkhan Truck Art'/>
					                    </div>
					                </div>
					            </div>
					            <div className='col-md-4'>
					                <div className='partner-info'>
					                    <div>
					                        <img src='/images/InterwoodLogo.png' alt='onx-interwood' title='karmuqabla'/>
					                    </div>
					                </div>
					            </div>
					            
					        	<div className='col-md-4'>
					                <div className='partner-info'>
					                    <div>
					                        <img src='/images/makeupcity.png' align='onx-makeupcity' alt='makeupcity' title='makeupcity'/>
					                    </div>
					                </div>
					            </div>
					        </div>
					        <div className='clearfix'/>
					        <div className='row mt-5'>
					            <div className='col-md-4'>
					                <div className='partner-info'>
					                    <div>
					                        <img src='/images/st-logo.png' alt='onx-St-London' title='hub'/>
					                    </div>
					                    
					                </div>
					            </div>
					            <div className='col-md-4'>
					                <div className='partner-info'>
					                    <div>
					                        <img src='/images/FARA.png' alt='onx-FARA'/>
					                    </div>
					                    
					                </div>
					            </div>
					        </div>
					        <div className='clearfix'/>
					    </div>
					</section>
					{/*
					<!-- <section className='py-5 pay_hours'>
					    <div className='container'>
					        <h2 className='txt-white main-heading text-center mt-4'>Pay by the hours</h2>
					        <p className='txt-white mt-4 text-center'>
					            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do<br/>
					            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut<br/>
					            enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea 
					        </p>
					        <div className='text-center py-4'>
					            <img src='/images/gar_bg_2157.png'>
					        </div>
					    </div>
					</section>


					<section className='py-lg-5 pt-md-0 pb-sm-5   mt-5'>
					    <div className='container'>
					        <h2 className='txt-black mb-5 main-heading text-center'>Our Partners</h2>
					        <div className='row text-center'>
					            <div className='PL-box'>
					                <a href=''><img src='/images/partners-4.png'></a>
					            </div>
					            <div className='PL-box'>    
					                <a href=''><img src='/images/partners-5.png'></a>
					            </div>
					            <div className='PL-box'>
					                <a href=''><img src='/images/partners-6.png'></a>
					            </div>
					            <div className='PL-box'>
					                <a href=''><img src='/images/partners-7.png'></a>
					            </div>
					            <div className='PL-box'>
					                <a href=''><img src='/images/partners-8.png'></a>
					            </div>
					        </div>
					    </div>
					</section> -->


					<!--<section className='py-5'>
					    <div className='container'>
					        <h2 className='txt-black main-heading text-center mb-5'>Case Studies</h2>
					        <div className='row'>
					            <div className='col-lg-4 col-md-4 blog-box'>
					            <a href=''>
					                <div className='blog-img'>
					                    <img src='/images//blog1.PNG'>
					                </div>
					                <div className='blog-disc'>
					                    <div className='blog-heading'>Case Studies #1</div>
					                    <div className='blog-details txt-pink'>Lorem ipsum dolora sit amet, consectetur adipisicing elit, sed do </div>
					                </div>
					            </a>
					            </div>

					            <div className='col-lg-4 col-md-4 blog-box'>
					            <a href=''>
					                <div className='blog-img'>
					                    <img src='/images//blog2.PNG'>
					                </div>
					                <div className='blog-disc'>
					                    <div className='blog-heading'>Case Studies #2</div>
					                    <div className='blog-details txt-pink'>Lorem ipsum dolora sit amet, consectetur adipisicing elit, sed do </div>
					                </div>
					            </a>    
					            </div>

					            <div className='col-lg-4 col-md-4 blog-box'>
					            <a href=''> 
					                <div className='blog-img'>
					                    <img src='/images//blog3.PNG'>
					                </div>
					                <div className='blog-disc'>
					                    <div className='blog-heading'>Case Studies #3</div>
					                    <div className='blog-details txt-pink'>Lorem ipsum dolora sit amet, consectetur adipisicing elit, sed do </div>
					                </div>
					            </a>    
					            </div>
					        </div>
					        <div className='text-center'>
					            <a href='' className='read-more'>See More</a>
					        </div>
					    </div>
					</section>  

					<section className='Ebook-sec'>
					    <div className='container'>
					        <div className='row'>
					            <div className='col-lg-6 py-5'>
					                <h2 className='txt-black my-4 main-heading'>Download E-book</h2>
					                <p className='txt-pink'>Lorem ipsum dolor sit amet, consectetur adipisicing<br/>elit, sed do eiusmod tempor incididunt ut labore<br/>et dolore magna aliqua. </p>
					            </div>
					            <div className='col-lg-6'>
					                <img src='./images/ebook.png'>
					            </div>
					        </div>
					    </div>
					</section>


					<section className='py-5 mb-5'>
					    <div className='container'>
					        <h2 className='txt-black main-heading text-center mb-5'>Blog</h2>
					        <div className='row'>
					            <div className='col-lg-6 col-md-6 mb-5 blog-box'>
					            <a href=''>
					                <div className='blog-img'>
					                    <img src='/images//blog4.PNG'>
					                </div>
					                <div className='blog-disc'>
					                    <div className='blog-heading'>Blog arcticle #1
					                        <div className='blog_view txt-pink'><img src='./images/view.png'> 110</div>
					                    </div>
					                    <div className='blog-details txt-pink'>Lorem ipsum dolora sit amet, consectetur adipisicing elit, sed do </div>
					                </div>
					            </a>    
					            </div>

					            <div className='col-lg-6 col-md-6 mb-5 blog-box'>
					            <a href=''> 
					                <div className='blog-img'>
					                    <img src='/images//blog5.PNG'>
					                </div>
					                <div className='blog-disc'>
					                    <div className='blog-heading'>Blog arcticle #2
					                    <div className='blog_view txt-pink'><img src='./images/view.png'> 110</div>
					                </div>
					                    <div className='blog-details txt-pink'>Lorem ipsum dolora sit amet, consectetur adipisicing elit, sed do </div>
					                </div>
					            </a>    
					            </div>

					        </div>
					         
					    </div>
					</section>-->*/}
				</div>
				<Footer/>
			</React.Fragment>
		)
	}
}

export default Home