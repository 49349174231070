import React from 'react'
import { Link } from 'react-router-dom'

class Navigation extends React.Component {

	constructor(props) {
        super(props);
        this.state = {
        	submenu: null,
        	mobileMenu: null,
        	subMenuMobile: null
        }
    }

	setsubMenu(type) {
		this.setState({ submenu: type })
	}

	setmobileMenu() {
		const { mobileMenu } = this.state
		if (mobileMenu) {
			this.setState({ mobileMenu: false })
		} else {
			this.setState({ mobileMenu: true })
		}
	}

	setsubMenuMobile() {
		const { subMenuMobile } = this.state
		if (subMenuMobile) {
			this.setState({ subMenuMobile: false })
			this.setState({ submenu: false })
		} else {
			this.setState({ subMenuMobile: true })
		}
	}

	render() {
		const { submenu, mobileMenu, subMenuMobile } = this.state
		const show = submenu ? 'show' : ''
		const showmobileMenu = mobileMenu ? 'show' : ''
		const showsubMenuMobile = subMenuMobile ? 'showMobile' : ''
		return (
			<React.Fragment>
				<header>
					<div className='container'>
						<div className='row'>
							<div className='col-lg-3 col-sm-6 col-6'>
								<Link to='/'>
									<img src='/images/logo.png' alt='logo'/>
								</Link>
							</div>
							<div className='col-lg-9 col-sm-6 col-6'>
								<nav className='navbar navbar-expand-lg navbar-light pull-right'>
							      	<input type='checkbox' className='openSidebarMenu' id='openSidebarMenu'/>
									<label onClick={() => this.setmobileMenu()} className='sidebarIconToggle'>
										<div className='spinner diagonal part-1'></div>
										<div className='spinner horizontal'></div>
										<div className='spinner diagonal part-2'></div>
									</label>
									<div id='sidebarMenu' className={`${showmobileMenu}`}>
									{/*<!--<ul className='sidebarMenuInner m-0'>
									  <li className='nav-item active'>
									    <a className='nav-link ' href='index.html'>Home</Link>
									  </li>
									  <li className='nav-item'>
									    <a className='nav-link' href='What-We-Do.html'>What We Do</Link>
									  </li>
									  <li className='nav-item'>
									    <a className='nav-link' href='our-work.html'>Our Work</Link>
									  </li>
									  <li className='nav-item'>
									    <a className='nav-link' href='Case-Studies.html'>Case Studies</Link>
									  </li>
									  <li className='nav-item '>
									    <a className='nav-link' href='contact.html'>Get Started</Link>
									  </li>
									 
									</ul>-->*/}

										<div className='menu-main-menu-container'>
											<ul id='menu-main-menu' className='sidebarMenuInner m-0'>
												<li id='menu-item-82' className='nav-link menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-66 current_page_item menu-item-82'>
													<Link to='/' aria-current='page'>Home</Link>
												</li>
												<li id='menu-item-120' className='nav-link menu-item menu-item-type-post_type menu-item-object-page menu-item-120'>
													<Link to='/about-us/'>About Us</Link>
												</li>
												<li id='menu-item-80' className='nav-link menu-item menu-item-type-post_type menu-item-object-page menu-item-80'>
													<Link to='/what-we-do/'>What we do</Link>
												</li>
												<li id='menu-item-158' className='nav-link menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-158' onMouseEnter={() => this.setsubMenu(true)} onMouseLeave={() => this.setsubMenu(false)}>
													<Link to='#'  onClick={() => this.setsubMenuMobile()}>Our Services</Link>
													<ul className={`sub-menu ${show} ${showsubMenuMobile}`}>
														<div className='submenu-container'>
															<li id='menu-item-157' className='menu-item menu-item-type-post_type menu-item-object-page menu-item-157'>
															<Link to='/development/'>Development</Link>
														</li>
														<li id='menu-item-155' className='menu-item menu-item-type-post_type menu-item-object-page menu-item-155'>
															<Link to='/optimization/'>Optimization</Link>
														</li>
														<li id='menu-item-154' className='menu-item menu-item-type-post_type menu-item-object-page menu-item-154'><Link to='/integrations/'>Integrations</Link></li>
														<li id='menu-item-156' className='menu-item menu-item-type-post_type menu-item-object-page menu-item-156'><Link to='/marketing/'>Marketing</Link></li>
													</div>
													</ul>
												</li>
												<li id='menu-item-107' className='nav-link menu-item menu-item-type-post_type menu-item-object-page menu-item-107'>
													<Link to='/get-started/'>Get Started</Link></li>
											</ul>
										</div>						  
									</div>
							    </nav> 
							</div>     
						</div>
					</div>
				</header>
			</React.Fragment>
		)
	}
}

export default Navigation