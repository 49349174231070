import React from 'react'
import Header from './Header'
import Footer from './Footer'

class WhatWeDo extends React.Component {

	render() {
		return (
			<React.Fragment>
				<Header title='404'/>
				<div className='main'>
					<div id="content" className="site-content">
						<div className='container mt-6 mb-6' style={{textAlign: 'center'}}>
							<h1>404</h1>
							<p>Sorry the page you are looking for was not found</p>
						</div>
					</div>
				</div>
				<Footer/>
			</React.Fragment>
		)
	}
}

export default WhatWeDo