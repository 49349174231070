import React from 'react'
import Header from './Header'
import Footer from './Footer'

class Integration extends React.Component {

	render() {
		return (
			<React.Fragment>
				<Header title='Integration'/>
				<div className='main'>
					<div className="container">	
						<p><strong>Payment Gateways</strong><br/>If you’ve ever purchased anything online before, you’ve touched a payment gateway, and probably all without realizing it! When you click “purchase” or “buy now,” a series of transactions are occuring between and website and its payment gateway that result in a successful purchase and the eventual shipment of your goods.</p>
						<p>A payment gateway is a service that allows you to securely accept credit card payments from customers shopping on your website. True to its name, the gateway’s role is to pass a customer’s credit card information through an authentication service, then onto your website, so that you can use their funds to complete a purchase.</p>
						<p>A gateway must be fully integrated with a website to make the process of payment seamless. If the integration is not set up correctly, customers may leave, or experience confusing errors that drive them away.</p>
						<p>We provide integrations with all of the leading payment gateway providers in the world. Take your pick!</p>
						<p>Visa<br/>Mastercard<br/>Paypal API<br/>Stripe API<br/>2Checkout<br/>Authorize.Net<br/>AliPay<br/>PayFast<br/>Cybersource</p>
						<p><strong>Shipping and Fulfillment</strong></p>
						<p>You put a lot of effort into making sure your customers have a great experience. You approve the images, you tweak your store, and you write the emails, all with the goal of leaving your customers happy.</p>
						<p>But when it comes to shipping, it can feel like you’re handing your brand over to a stranger.</p>
						<p>With some thought and planning, however, it doesn’t have to feel that way—which is a good thing, because shipping is a key part of your business. It’s the point where a customer finally experiences your product in person, and it can also represent a major expense in your business, depending on your shipping strategy.</p>
						<p>We provide integrations with all of the leading payment shipment providers in the world. Take your pick!</p>
						<p>UPS<br/>
						FEDEX<br/>
						TCS<br/>
						DHL<br/>
						Leopards</p>
						<p><strong>Accounting and Bookkeeping</strong></p>
						<p>The tax forms are in the mail, the shoebox full of receipts is overflowing, and your desk is sporting a shiny new dent thanks to repeated impact with your forehead.</p>
						<p>The above can only mean one thing: tax season is here. And unless you have a good handle on your online store’s finances, your blood pressure is probably rising as fast as your paperwork.</p>
						<p>Fortunately, just a few basic practices can make your ecommerce accounting and tax filing a whole lot easier and less stressful. If you want to get on top of your books but don’t know where to start, we’re here to help</p>
						<p>Quickbooks<br/>Zapier<br/>Freshbooks<br/>Authorize.NET Reporting</p> 
					</div>
				</div>
				<Footer/>
			</React.Fragment>
		)
	}
}

export default Integration