import React from 'react'
import Header from './Header'
import Footer from './Footer'

class Optimization extends React.Component {

	render() {
		return (
			<React.Fragment>
				<Header title='Optimization'/>
				<div className='main'>
					<div className="container">
						<p><strong>Conversion Rate Optimization</strong><br/>Conversion is at the heart of every marketing campaign. Yet companies continue to spend tens of thousands of dollars on getting traffic to unengaging, low-converting websites. And let’s face it. The competition is fierce. We live in an age where everything has been commoditized. Your potential customers have many options to choose from. To break through the noise, you must handcraft every touchpoint to give your visitor’s a thrill, an experience.</p>
						<p>Our full conversion optimization service examines every touch point your customer has with your business. Our team follows a detailed SHIP optimization process and the Conversion framework to determine what areas of your website are not working and how to fix them. At the same time, our team identifies and helps you implement new and strategic business opportunities. We work together to transform your company into an agile and data-driven culture with a sharp focus on customer acquisition and retention strategies.</p>
						<p><strong>Speed Optimization</strong><br/>A strategy that is often overlooked, page speed optimization services are crucial to great SEO. Without ensuring that your website loads quickly for visitors, your site will likely lose conversions and revenue. Nearly half of web searchers won’t even wait three seconds for a page to load before bouncing to another site. </p>
						<p>Our highly skilled web development team has created a proven, fast, and cost-effective process for completing site speed optimizations for the brands we partner with. We handle the entire process from A to Z and eliminate unnecessary elements on your site that could be slowing down your page load time.</p>
						<p><strong>Sales Funnel Optimizaton</strong><br/>A Sales Funnel is the path website visitors take on the way to buying your product or service. Some people never leave the top of the sales funnel, while others reach the very end. Wether you’re aware of it or not but your sales funnel exists. If you’re not aware of the funnel, you’re losing potential business.</p>
						<p>Onx taps every touchpoint of your potential customer through a well structured and planned sales funnel. Sales funnel is the DNA of digital marketing. It has the potential of growing business revenues exponentially and set up an irresistible marketing campaign.</p> 
					</div>
				</div>
				<Footer/>
			</React.Fragment>
		)
	}
}

export default Optimization