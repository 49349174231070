import React from 'react'
import Header from './Header'
import Footer from './Footer'

class WhatWeDo extends React.Component {

	render() {
		return (
			<React.Fragment>
				<Header title='What We Do'/>
				<div className='main'>
					<div id="content" class="site-content">
					<section class="mt-0  mt-md-5 py-5">
						<div class="container">
							<div class="row">
								<div class="col-lg-6 col-md-6 text-center d-md-none d-sm-block">
									<div class="text-center pt-5 mt-5">
										<img class="mt-5" src="/images/info-ref-1252.png" alt="Ideation" title="Ideation"/>
									</div>
								</div>
								<div class="col-lg-6 col-md-6 pt-5 pr-lg-5 mb-5">
									<h2 class="main-heading txt-black mmt-0 mt-md-5">Ideation</h2>
									<p class="txt-pink">Every project requires a thorough understanding of the requirements. In Ideation, we sit with you and only listen. </p>
									<p class="txt-pink">We understand how your bussiness works, what are your requirements and how could we serve you best. For us the most important part in Ideation is to make sure that the end product is a unique powerhouse built to cater all your requirements.</p>
									<a href="/get-started" class="btn btn-custom txt-white mt-4">Get Started</a>
								</div>
								<div class="col-lg-6 col-md-6 text-center d-sm-none d-md-block">
									<div class="text-center pt-5 mt-5">
										<img class="mt-5" src="/images/info-ref-1252.png" alt='Ideation'/>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section class="header-WWD-secBg py-5">
						<div class="container">
							<div class="row">
								<div class="col-lg-6 col-md-6 text-center">
									<div class="text-center pt-5">
										<img class="mt-5" src="/images/info-ref-1253.png" alt="Design" title="Design"/>
									</div>
								</div>
								<div class="col-lg-6 col-md-6 pt-5 pr-lg-5 mb-5">
									<h2 class="main-heading txt-black mmt-0 mt-md-5">Design</h2>
									<p class="txt-pink">In Design, all the effort spent to understand the process and requirment comes to life. It is where our thinkers, innovators and designers come together and sit to craft the perfect looking end product for you. </p>
									<p class="txt-pink">We build wireframes, design funnels, add colors to make the most beautiful looking product that is both user friendly and easy to use.</p>
									<a href="/get-started" class="btn btn-custom txt-white mt-4">Get Started</a>
								</div>
							</div>  
						</div>
					</section>
					<section class="py-5">
						<div class="container"> 
							<div class="row">
								
								<div class="col-lg-6 col-md-6 text-center d-md-none d-sm-block">
									<div class="text-center pt-5 mt-5">
									 <img class="mt-5" src="/images/info-ref-1254.png" alt="Development" title="Development"/>
									</div>
								</div>
								<div class="col-lg-6 col-md-6 pt-5 pr-lg-5 mb-5">
									<h2 class="main-heading txt-black mmt-0 mt-md-5">Development</h2>
									<p class="txt-pink">In all honesty, we could have the best looking eCommerce out there but if it is full of bugs, slow and inefficient it is of no use to your customers. This is where our expert developers come in.</p>
									<p class="txt-pink">We use agile solutions to develop the best performing eCommerce solution for you. We make sure it is secure, scalable and performs well.</p>
									<a href="/get-started" class="btn btn-custom txt-white mt-4">Get Started</a>
									 
								</div>
								<div class="col-lg-6 col-md-6 text-center d-sm-none d-md-block">
									<div class="text-center pt-5 mt-5">
									 <img class="mt-5" src="/images/info-ref-1254.png" alt="Development" title="Development"/>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section class="header-WWD-secBg py-5">
						<div class="container"> 
							<div class="row">
								<div class="col-lg-6 col-md-6 text-center">
									<div class="text-center pt-5 mt-5">
									 <img class="" src="/images/info-ref-1255.png" alt="Implementation" title="Implementation"/>
									</div>
								</div>
								<div class="col-lg-6 col-md-6 pt-5 pr-lg-5 mb-5">
									<h2 class="main-heading txt-black mmt-0 mt-md-5">Implementation</h2>
									<p class="txt-pink">What domain to buy to have the best results in search engines? What is the best hosting solution to host your website on? Autoscaling? Security? CDN? SSL? Caching?</p>
									<p class="txt-pink">In implementation we take care of all these problems so you dont have to worry about any of these. Ever! </p>
									<a href="/get-started" class="btn btn-custom txt-white mt-4">Get Started</a>
									 
								</div>
								
							</div>
						</div>
					</section>
					<section class="py-5">
						<div class="container">
							<div class="row">
								<div class="col-lg-6 col-md-6 text-center d-md-none d-sm-block">
									<div class="text-center pt-5 mt-5">
									 <img class="mt-5" src="/images/info-ref-1256.png" alt='Digital Audit'/>
									</div>
								</div>
								<div class="col-lg-6 col-md-6 pt-5 pr-lg-5 mb-5">
									<h2 class="main-heading txt-black mmt-0 mt-md-5">Digital Audit</h2>
									<p class="txt-pink">The real struggle of an eCommerce starts once its live! Who to advertise, what to advertise and how to advertise. To analyize through progress and status metrics and have a caculated way forward is necessary for an eCommerce.</p>
									<p class="txt-pink">In the digital Audit from configuring markeing automation and sales funnels that convert to formulating a digital advertising strategy our focus is to get you the maximum return on your investment.</p>
									<a href="/get-started" class="btn btn-custom txt-white mt-4">Get Started</a>
									 
								</div>
								<div class="col-lg-6 col-md-6 text-center d-sm-none d-md-block">
									<div class="text-center pt-5 mt-5">
									 <img class="mt-5" src="/images/info-ref-1256.png" alt="Digital Audit" title="Digital Audit"/>
									</div>
								</div>
							</div>  
						</div>
					</section>
					</div>
				</div>
				<Footer/>
			</React.Fragment>
		)
	}
}

export default WhatWeDo