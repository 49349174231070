import React from 'react'
import Header from './Header'
import Footer from './Footer'
import ReactPixel from 'react-facebook-pixel';

const advancedMatching = { em: 'some@email.com' }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};
ReactPixel.init('1045108532952800', advancedMatching, options);

class GetStarted extends React.Component {

	constructor(props) {
        super(props);
        this.state = {
        	submitted: false,
        	formData: {
        		name: '',
	        	email: '',
	        	contact: '',
	        	service: '',
	        	message: ''
	        }
        }
    }

	handleChange(event) {
	    const target = event.target;
        const value = target.value;
        const name = target.name;

        let form = this.state.formData;
        form[name] = value;
        this.setState({ formData: form });
        ReactPixel.trackCustom('FilledField', form);
	    
	}

	handleSubmit(event) {
	    ReactPixel.track('Lead');
	    this.setState({ submitted: true });

	    const target = event.target;
        const value = target.value;
        const name = target.name;

        let form = this.state.addressData;
        form[name] = value;

        this.setState({ formData: form });
	}

	render() {
		return (
			<React.Fragment>
				<Header title='Lets discuss!'/>
				<div className='main'>
					<section className='mt-0 mt-md-5 mb-5'>
					    <div className='container'>
					    	<div className='row'>
					    		<div className='col-sm-12 col-lg-6 pr-lg-5 mb-5'>
					          		<h2 className='main-heading txt-black mmt-0'>Let’s get started</h2>
					          		<p className='txt-gray'>Feel free to contact us at any time. We are there to solve your problems.</p>
					          		<p className='txt-gray'>You can also send us your queries directly at <a href='mailto:contact@onx.digital'>contact@onx.digital</a></p>
					          		<div className='social-links'>
					            		<span>
					            			<a href='https://www.facebook.com/onxdigital/' rel='noopener noreferrer' target='_blank'>
					            				<i className='fa fa-facebook'/>
					            			</a>
					            		</span>
					              		<span>
					              			<a href='https://www.linkedin.com/company/onxdigital/' rel='noopener noreferrer' target='_blank'>
					              				<i className='fa fa-linkedin-square'/>
					              			</a>
					              		</span>
					          		</div>
					        	</div>
					        	<div className='col-sm-12 col-lg-6 contact-form'>
					        		{ this.state.submitted &&
					        			<div class="alert alert-success" role="alert">
										  We have received your inquiry and will get back to you soon!
										</div>
					        		}
					          		<div role='form' className='wpcf7' id='wpcf7-f99-o1' lang='en-US' dir='ltr'>
										<div className='screen-reader-response' role='alert' aria-live='polite'></div>
											<form name="contact" method="post" data-netlify="true" onSubmit={this.handleSubmit}>
												<input type="hidden" name="form-name" value="contact" />
												<div className='form-group'>
											    	<span className='wpcf7-form-control-wrap your-name'>
											    		<input type='text' name='name' value={this.state.formData.name} size='40' className='wpcf7-form-control wpcf7-text wpcf7-validates-as-required form-control' aria-required='true' aria-invalid='false' placeholder='Name*' onChange={(e) => this.handleChange(e)}/>
											    	</span>
											  	</div>
												<div className='form-group'>
											    	<span className='wpcf7-form-control-wrap your-email'>
											    		<input type='email' name='email' value={this.state.formData.email} size='40' className='wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email form-control' aria-required='true' aria-invalid='false' placeholder='Email*' onChange={(e) => this.handleChange(e)}/>
											    	</span>
											  	</div>
											  	<div className='form-group'>
											    	<span className='wpcf7-form-control-wrap'>
											    		<input type='text' name='contact' value={this.state.formData.contact} size='40' className='wpcf7-form-control wpcf7-text form-control' aria-required='true' aria-invalid='false' placeholder='Contact Number*' onChange={(e) => this.handleChange(e)}/>
											    	</span>
											  	</div>
											  	<div className='form-group'>
											    	<span className='wpcf7-form-control-wrap your-email'>
											    		<input type='text' name='service' value={this.state.formData.service} size='40' className='wpcf7-form-control wpcf7-text form-control' aria-required='true' aria-invalid='false' placeholder='Service' onChange={(e) => this.handleChange(e)}/>
											    	</span>
											  	</div>
												<div className='form-group'>
											    	<span className='message'><textarea name='message' cols='40' rows='10' className='wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required form-control' aria-required='true' aria-invalid='false' placeholder='Message' onChange={(e) => this.handleChange(e)}>{this.state.formData.message}</textarea></span>
											  	</div>
												<p><input type='submit' value='Send' className='wpcf7-form-control wpcf7-submit btn btn-custom txt-white'/></p>
												<div className='wpcf7-response-output' role='alert' aria-hidden='true'/>
											</form>
											</div>
										</div>
					      			</div>  
					    		</div>
					  </section>
				</div>
				<Footer/>
			</React.Fragment>
		)
	}
}

export default GetStarted